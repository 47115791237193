import _ from 'lodash'
import React, { Component, FC, Fragment } from 'react'
import { GalleryDetailReduxProps } from '../../redux/reducers/maps/GalleryDetailRedux'
import QueryString from 'query-string'
import { useLocation, Location } from 'react-router-dom'
import store from '../../redux'
import { fetchGalleryDetailThunk } from '../../redux/reducers/thunks/GalleryDetailThunk'
import { EGallery, IGallery, IServiceImageDTO } from '../../models'
import SideNav from '../../components/SideNav'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { AppConstants } from '../../utility'
import './styles.css'

interface IProps extends GalleryDetailReduxProps {}
class GalleryDetailContent extends Component<IProps & { location: Location }> {
  get gallery(): IGallery | null {
    return this.props.GalleryDetailSlice.Gallery
  }
  getIdFromQuery = (): string | undefined => {
    const query = QueryString.parse(this.props.location.search)
    return query.id as string
  }
  getClassName = (key: EGallery) => {
    switch (key) {
      case EGallery.GalleryWedding:
        return { className: 'wedding', tag: 'Wedding' }
      case EGallery.GalleryEngagement:
        return { className: 'engagement', tag: 'Engagement' }
      case EGallery.GalleryFamily:
        return { className: 'family', tag: 'Family' }
      case EGallery.GalleryHairAndMakeup:
        return { className: 'hair-and-makeup', tag: 'Hair and Makeup' }
      default:
        return { className: '', tag: '' }
    }
  }
  renderImages = () => {
    let images = Array.from(Object.values(this.props.GalleryDetailSlice.images.entities)) as IServiceImageDTO[]
    images = images.sort((a, b) => a.Description.index - b.Description.index)
    return images.map((e, i) => {
      const obj = this.getClassName(this.gallery?.Type as EGallery)
      let url = 'images/portfolio/portfolio-photo1.png'
      if (e.ImageUri) url = AppConstants.baseUrl + e.ImageUri
      return (
        <div key={i} className="col-lg-4 col-md-6 col-sm-12 col-12 mb-30">
          <a href={url} className="gallery-item" data-fancybox="gallery">
            <img src={url} alt="portfolio-picture" title="portfolio-picture" />
            <div className="gallery-hover hover-style-one">
              <span className="gallery-popup">+</span>
            </div>
          </a>
          {/* <div className="gallery-info">
            <h3>{e.Title}</h3>
            <span className="sub-title">{obj.tag}</span>
          </div> */}
        </div>
      )
    })
  }
  componentDidMount(): void {
    store.dispatch(fetchGalleryDetailThunk({ galleryId: this.getIdFromQuery() }))
  }
  render() {
    if (!this.gallery) return <></>
    return (
      <Fragment>
        {/* header sidenav  */}

        <SideNav />

        <div className="page-wrapper">
          <div className="body-cover"></div>

          {/* Preloader */}
          <div className="preloader"></div>

          {/* Main Header */}
          <Header />
          {/* End Main Header */}
          <main>
            {/* Gallery section */}
            <section className="section gallery-section pt-md-100 pt-45">
              <div className="container">
                <div
                  className="content-title center-align mb-md-90 mb-30 wow fadeInUp"
                  data-wow-delay="0.3s"
                  data-wow-duration="0.5s"
                >
                  <h2>{this.gallery.Title ?? 'GALLERY'}</h2>
                  <span className="sub-title">AT VERO EOS ET ACCUSAMUs</span>
                </div>
                <div className="content-row wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.5s">
                  <div className="portfolioContainer row height-auto">
                    {this.renderImages()}
                    {/* {this.galleries.map((e, i) => {
                      const obj = this.getClassName(e.Type)
                      const cn = `col-lg-4 col-md-6 col-sm-12 col-12 mb-30  ${obj.className}`
                      let ImageUri = _.get(e, 'ServiceImages[0].ImageUri')
                      let url = 'images/portfolio/portfolio-photo1.png'
                      if (ImageUri) url = AppConstants.baseUrl + ImageUri
                      return (
                        <div key={i} className={cn} onClick={() => this.handleClickNavToDetail(e)}>
                          <div className="gallery-item" style={{ cursor: 'pointer' }}>
                            <img src={url} alt="portfolio-photo" title="portfolio-photo" />
                            <div className="gallery-hover hover-style-one">
                              <span className="gallery-popup">+</span>
                            </div>
                          </div>
                          <div className="gallery-info">
                            <h3>{e.Title}</h3>
                            <span className="sub-title">{obj.tag}</span>
                          </div>
                        </div>
                      )
                    })} */}
                  </div>
                </div>
              </div>
            </section>
            {/* End Gallery section*/}
          </main>

          {/* Main Footer */}
          <Footer />
          {/* End Main Footer */}
        </div>
      </Fragment>
    )
  }
}
const GalleryDetailScreen: FC<IProps> = (props) => <GalleryDetailContent {...props} location={useLocation()} />
export default GalleryDetailScreen
