import { Component, Fragment } from 'react'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import SideNav from '../../components/SideNav'
import { ContactUsReduxProps } from '../../redux/reducers/maps/ContactUsRedux'

interface IProps extends ContactUsReduxProps {}

export default class ContactUsScreen extends Component<IProps> {
  render() {
    return (
      <Fragment>
        {/* header sidenav  */}

        <SideNav />

        <div className="page-wrapper">
          <div className="body-cover"></div>

          {/* Preloader */}
          <div className="preloader"></div>

          {/* Main Header */}
          <Header />
          {/* End Main Header */}

          <main>
            {/* wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.5s */}

            {/* Map Section */}
            <section className="section map-section height-766 pt-0 pb-0">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d77159.73241344269!2d-75.064918643597!3d39.85053155162902!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6cd95ddbef901%3A0xb2449216b83b4918!2s1048%20Coronet%20Ln%2C%20Somerdale%2C%20NJ%2008083%2C%20Hoa%20K%E1%BB%B3!5e0!3m2!1svi!2s!4v1697642505182!5m2!1svi!2s"
                width="800"
                height="600"
                style={{ border: 0 }}
                loading="lazy"
                title="map-google"
                {...{ referrerpolicy: 'no-referrer-when-downgrade' }}
              ></iframe>
            </section>
            {/*End Map Section */}
            {/* Contact Form Section */}
            <section className="section contact-section pt-md-0 pb-md-60 pb-30">
              <div className="container">
                <div className="content-row">
                  <div className="max-width-1070">
                    <div className="contact-form mt-md--189 wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.5s">
                      <span className="contact-form-vector">
                        <img src="images/vector-leaf.svg" alt="leaf-icon" title="leaf-icon" />
                      </span>
                      {/* <div className="content-title mb-30 center-align">
                        <span className="top-title">CONTACT US</span>
                        <h2>Get In touch</h2>
                      </div>
                      <div className="input-div">
                        <input type="text" name="name" placeholder="full name" />
                      </div>
                      <div className="input-div">
                        <input type="email" name="Emaıl" placeholder="Emaıl" />
                      </div>
                      <div className="input-div">
                        <input type="text" name="address" placeholder="address" />
                      </div>
                      <div className="input-div">
                        <textarea placeholder="MEAL PREFERENCES" defaultValue={''} />
                      </div>
                      <div className="input-div">
                        <button type="submit" className="theme-btn full-width">
                          SEND YOUR MESSAGE
                          <span />
                        </button>
                      </div> */}
                      <iframe
                        src="https://docs.google.com/forms/d/e/1FAIpQLSdXU555oHs0BlnCtL4woinpA8u77nR2yrhl3ITeyK3UhWyO9w/viewform?embedded=true"
                        width="100%"
                        height="1750"
                        title="form-google"
                        {...{ frameborder: '0', marginheight: '0', marginwidth: '0' }}
                      >
                        Loading…
                      </iframe>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* End Contact Form Section */}
            {/* Address Box Section */}
            <section className="section address-box-section pt-md-60 pt-30 pb-md-90 pb-30">
              <div className="container">
                <div className="content-row">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                      <div className="address-box center-align mb-30 wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.5s">
                        <span className="address-box-icon">
                          <img src="images/location-pin.svg" alt="location-pin" title="location-pin" width={60} height={60} />
                        </span>
                        <p>
                          <strong>ADDRESS</strong>
                          <span className="font-family-gruppo">
                            1048 Coronet Ln, Somerdale
                            <span style={{ display: 'inline-block', opacity: 0 }}>,</span>
                            <br />
                            NJ 08083
                          </span>
                        </p>
                        {/* <a href="#" className="load-more">
                          Check Location
                        </a> */}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                      <div className="address-box center-align mb-30 wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.5s">
                        <span className="address-box-icon">
                          <img src="images/love-letter.svg" alt="love-letter" title="love-letter" width={55} height={38} />
                        </span>
                        <p>
                          <strong>EMAIL</strong>Email Us At:
                          <br />
                          <span className="font-family-gruppo">lanhairandmakeup@gmail.com</span>
                        </p>
                        {/* <a href="#" className="load-more">
                          Contact Form
                        </a> */}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                      <div className="address-box center-align mb-30 wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.5s">
                        <span className="address-box-icon">
                          <img src="images/love-message.svg" alt="love-message" title="love-message" width={48} height={60} />
                        </span>
                        <p>
                          <strong>PHONE</strong>Call Us At:
                          <br />
                          <span className="font-family-gruppo">856-878-8298</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* End Address Box Section */}
          </main>

          {/* Main Footer */}
          <Footer />
          {/* End Main Footer */}
        </div>
      </Fragment>
    )
  }
}
