import React, { Component, Fragment } from 'react'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import SideNav from '../../components/SideNav'

export default class BlogSingleScreen extends Component {
  render() {
    return (
      <Fragment>
        {/* header sidenav  */}

        <SideNav />

        <div className="page-wrapper">
          <div className="body-cover"></div>

          {/* Preloader */}
          <div className="preloader"></div>

          {/* Main Header */}
          <Header />
          {/* End Main Header */}

          <main>
            {/* wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.5s */}

            {/* Post section */}
            <section className="section blog-post-detail pt-md-100 pt-45 pb-30">
              <div className="container">
                <div
                  className="content-title max-title3 mb-md-100 mb-60 wow fadeInUp"
                  data-wow-delay="0.3s"
                  data-wow-duration="0.5s"
                >
                  <span className="top-title">weddıng</span>
                  <h1>8 REASONS YOU SHOULD HIRE A WEDDING PLANNERFINAL PREPARATION</h1>
                  <div className="blog-post-meta">
                    <div className="blog-post-author">
                      <a href="#">alı tufan</a>
                    </div>
                    <div className="blog-post-category">
                      <a href="#">July 10, 2023 </a>
                    </div>
                    <div className="blog-post-comments-holder">
                      <a href="#">4 Comment</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-img mb-md-120 mb-60 wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.5s">
                <img src="images/blog/blog-post17.png" alt="blog-post" title="blog-post" />
              </div>
              <div className="content-row mb-60 wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.5s">
                <ul className="blog-social">
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                </ul>
                <div className="container">
                  <div className="blog-post-detail-info wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.5s">
                    <div className="font-25">
                      Proin faucibus nec mauris a sodales, sed elementum mi tincidunt. Sed eget viverra egestas nisi in consequat.
                      Fusce sodales augue a accumsan. Cras sollicitudin, ipsum eget blandit pulvinar. Integer tincidunt.{' '}
                    </div>
                    <p>
                      Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius
                      laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies
                      nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero,
                      sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem.
                      Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis faucibus. Nullam quis
                      ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed fringilla mauris sit amet nibh. Donec
                      sodales sagittis magna. Sed consequat, leo eget bibendum sodales, augue velit cursus nunc. Donec quam felis,
                      ultricies nec.
                    </p>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="content-img mb-30">
                          <img src="images/blog/blog-post3.png" alt="blog-post" title="blog-post" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="content-img mb-30">
                          <img src="images/blog/blog-post9.png" alt="blog-post" title="blog-post" />
                        </div>
                      </div>
                    </div>
                    <p>
                      Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem
                      aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt.
                    </p>
                    <blockquote>
                      Curabitur varius eros et lacus rutrum consequat. Mauris sollicitudin enim condimentum, luctus enim justo
                      non, molestie nisl. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget
                      dolor. Aenean massa.
                    </blockquote>
                    <p>
                      Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius
                      laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies
                      nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero,
                      sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem.
                      Maecenas nec odio et ante tincidunt tempus.{' '}
                    </p>
                    <div className="tag_cloud">
                      <a href="#" className="current">
                        groom
                      </a>
                      <a href="#">wedding</a>
                      <a href="#">rose</a>
                      <a href="#">dress</a>
                      <a href="#">fashion</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blog-testimonilas-section wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.5s">
                <div className="container">
                  <div className="blog-testimonilas">
                    <span className="blog-testimonilas-img">
                      <img src="images/blog/brooklyn-simmons.png" alt="brooklyn-simmons" title="brooklyn-simmons" />
                    </span>
                    <div className="blog-testimonilas-info">
                      <h3>Brooklyn Simmons</h3>
                      <span className="author-position">Medical Assistant</span>
                      <p>
                        Etiam vitae leo et diam pellentesque porta. Sed eleifend ultricies risus, vel rutrum erat commodo ut.
                        Praesent finibus congue euismod. Nullam scelerisque massa vel augue placerat, a tempor sem egestas.
                        Curabitur placerat finibus lacus.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="post-comment-row wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.5s">
                  <h2>4 REVIEW FOR BRIDE DRESS</h2>
                  <ul className="post-comment-list">
                    <li>
                      <div className="post-comment">
                        <div className="post-comment-img">
                          <img src="images/blog/author.png" alt="author" title="author" />
                        </div>
                        <div className="post-comment-text">
                          <h3>Ali Tufan</h3>
                          <span className="post-date">October 31, 2023</span>
                          <span className="post-category">The best Design</span>
                          <p>
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean
                            massa. Cum sociis Theme natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="post-comment">
                        <div className="post-comment-img">
                          <img src="images/blog/author.png" alt="author" title="author" />
                        </div>
                        <div className="post-comment-text">
                          <h3>Ali Tufan</h3>
                          <span className="post-date">October 31, 2023</span>
                          <span className="post-category">The best Design</span>
                          <p>
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean
                            massa. Cum sociis Theme natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <h2>Add a review</h2>
                  <div className="post-comment-form">
                    <div className="input-div">
                      <input type="text" name="text" placeholder="full name" />
                    </div>
                    <div className="input-div">
                      <input type="email" name="email" placeholder="Emaıl" />
                    </div>
                    <div className="input-div">
                      <textarea placeholder="YOUR REVIEW" defaultValue={''} />
                    </div>
                    <div className="input-div">
                      <button type="submit" className="theme-btn">
                        SUBMIT
                        <span />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* End Post section*/}

            {/* pagination */}
            <div className="sk-navigation-two bg_alice">
              <div className="container">
                <div className="sk-navigation wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.5s">
                  <div className="sk-navigation-prev">
                    <a href="#">
                      <i className="fal fa-long-arrow-left" />{' '}
                      <span>
                        prev post<span>BRIDESMAIDS</span>
                      </span>
                    </a>
                  </div>
                  <div className="sk-navigation-back-btn">
                    <a href="#" className="">
                      <img src="images/back-btn-blue.svg" alt="back" title="back" width={17} height={17} />
                    </a>
                  </div>
                  <div className="sk-navigation-next">
                    <a href="#">
                      <span>
                        next post<span>GROOMSMEN</span>
                      </span>{' '}
                      <i className="fal fa-long-arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* end */}
          </main>

          {/* Main Footer */}
          <Footer />
          {/* End Main Footer */}
        </div>
      </Fragment>
    )
  }
}
