import { configureStore } from '@reduxjs/toolkit'
import Reducers from './reducers'
import thunkMiddleware from 'redux-thunk'

const store = configureStore({
  reducer: Reducers,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat([thunkMiddleware])
  },
})

export default store
