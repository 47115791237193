import { IGallery } from '../../../models'
import { AppDispatch, RootState } from '../../type'
import { WindowScrollToTop } from '../../../utility'
import { fetchMakeupAndHairThunk } from '../thunks/MakeupAndHairThunk'
import { TDispatchRedux, TStateRedux, hocConnect } from '../hocs/hocConnect'
import MakeupAndHairScreen from '../../../views/MakeupAndHair'
import { MakeupAndHairSliceState } from '../slices/MakeupAndHairSlice'

export interface MakeupAndHairReduxState extends TStateRedux {
  MakeupAndHairSlice: MakeupAndHairSliceState
}

export interface MakeupAndHairReduxDispatch extends TDispatchRedux {}

export interface MakeupAndHairReduxProps extends MakeupAndHairReduxState, MakeupAndHairReduxDispatch {}

const mapStateToProps = (state: RootState): MakeupAndHairReduxState => ({
  Status: state.MakeupAndHairSlice.Status,
  MakeupAndHairSlice: state.MakeupAndHairSlice,
})

const mapDispatchToProps = (dispatch: AppDispatch): MakeupAndHairReduxDispatch => ({
  fetchInitial: () => {
    dispatch(fetchMakeupAndHairThunk())
    return {
      abort() {
        WindowScrollToTop()
      },
    }
  },
})

export const MakeupAndHairRedux = hocConnect(MakeupAndHairScreen, mapStateToProps, mapDispatchToProps)
