import { AxiosError } from 'axios'
import { ApiAlertContext } from '../../components/AlertGlobal'

export const CheckTokenVersion = async () => {
  // const res = await fetch('/Validate/Version')
  // const tokenVersion = res.headers.get('token_version')
  // const user = await authService.getUser()
  // if (user?.token_version !== tokenVersion) {
  //   await authService.userManager?.clearStaleState()
  //   await authService.userManager?.removeUser()
  //   await authService.userManager?.signinSilent()
  // }
}

export const ProcessRepose = async (err: AxiosError) => {
  if (err.response?.status === 403) {
    const dataError: { Code: number; Message: string } = err.response.data as any
    switch (dataError.Code) {
      case 1: {
        // authService.userManager?.removeUser()
        // authService.signIn({ returnUrl: authService.getReturnUrl() })
        break
      }
      default: {
        // window.location.replace(
        //     ApplicationPaths.Origin(`${process.env.PUBLIC_URL}/Forbidden`)
        // );
        break
      }
    }
  } else if (err.response?.status === 401) {
    // authService.userManager?.removeUser()
    // await authService.signIn({ returnUrl: process.env.PUBLIC_URL })
  } else if (err.code === 'ERR_CANCELED') {
  } else if ((err.response?.status ?? 500) >= 500) {
    if (err.code !== 'ERR_CANCELED') {
      ApiAlertContext.ApiAlert?.PushError('An error has occurred! (server error 500)')
    }
  } else if (err.response?.status === 404) {
    ApiAlertContext.ApiAlert?.PushError('An error has occurred! (server error 404)')
  }
  return Promise.reject(err)
}
