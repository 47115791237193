import { createSlice } from '@reduxjs/toolkit'
import { LazyStatus, TStateRedux } from '../hocs/hocConnect'
import { fetchPhotographyThunk } from '../thunks/PhotographyThunk'
import { IGallery, IPrice } from '../../../models'

export interface PhotographySliceState extends TStateRedux {
  Prices: IPrice[]
  Galleries: IGallery[]
  requestId?: string
}

// Define the initial state using that type
const initialState: PhotographySliceState = {
  Status: LazyStatus.Loading,
  Prices: [],
  Galleries: []
}

const PhotographySlice = createSlice({
  name: 'PhotographySlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPhotographyThunk.fulfilled, (state, action) => {
        if(state.requestId !== action.meta.requestId) return
        state.Status = LazyStatus.Loaded
        state.Prices = action.payload.Prices
        state.Galleries = action.payload.Galleries
      })
      .addCase(fetchPhotographyThunk.rejected, (state, action) => {
        if(state.requestId !== action.meta.requestId) return
        state.Status = LazyStatus.Error
      })
      .addCase(fetchPhotographyThunk.pending, (state, action) => {
        state.Status = LazyStatus.Loading
        state.requestId = action.meta.requestId
      })
  },
})

// export const {} = ServiceImageSlice.actions

export default PhotographySlice
