import { IGallery } from '../../../models'
import { AppDispatch, RootState } from '../../type'
import { WindowScrollToTop } from '../../../utility'
import { fetchPhotographyThunk } from '../thunks/PhotographyThunk'
import { TDispatchRedux, TStateRedux, hocConnect } from '../hocs/hocConnect'
import PhotographyScreen from '../../../views/Photography'
import { PhotographySliceState } from '../slices/PhotographySlice'

export interface PhotographyReduxState extends TStateRedux {
  PhotographySlice: PhotographySliceState
}

export interface PhotographyReduxDispatch extends TDispatchRedux {}

export interface PhotographyReduxProps extends PhotographyReduxState, PhotographyReduxDispatch {}

const mapStateToProps = (state: RootState): PhotographyReduxState => ({
  Status: state.PhotographySlice.Status,
  PhotographySlice: state.PhotographySlice,
})

const mapDispatchToProps = (dispatch: AppDispatch): PhotographyReduxDispatch => ({
  fetchInitial: () => {
    dispatch(fetchPhotographyThunk())
    return {
      abort() {
        WindowScrollToTop()
      },
    }
  },
})

export const PhotographyRedux = hocConnect(PhotographyScreen, mapStateToProps, mapDispatchToProps)
