import { createSlice } from '@reduxjs/toolkit'
import { LazyStatus, TStateRedux } from '../hocs/hocConnect'
import { fetchContactUsThunk } from '../thunks/ContactUsThunk'

export interface ContactUsSliceState extends TStateRedux {}

// Define the initial state using that type
const initialState: ContactUsSliceState = {
  Status: LazyStatus.Loading,
}

const ContactUsSlice = createSlice({
  name: 'ContactUsSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchContactUsThunk.fulfilled, (state, action) => {
        state.Status = LazyStatus.Loaded
      })
      .addCase(fetchContactUsThunk.rejected, (state) => {
        state.Status = LazyStatus.Error
      })
      .addCase(fetchContactUsThunk.pending, (state) => {
        state.Status = LazyStatus.Loading
      })
  },
})

// export const {} = ServiceImageSlice.actions

export default ContactUsSlice
