import { createAsyncThunk } from '@reduxjs/toolkit'
import { IGallery } from '../../../models'
import GalleryService from '../../../services/GalleryService'

export const fetchGalleryDetailThunk = createAsyncThunk<IGallery | null, { galleryId?: string }>(
  'fetchGalleryDetailThunk',
  async (params, thunkAPI) => {
    const res = await GalleryService.Detail(params.galleryId, thunkAPI.signal)
    return res
  }
)
