import React, { Component } from 'react'
import { Box, SxProps, Theme } from '@mui/material'

interface IProps {
  src: string
  fallbackSrc?: string
  baseUrl?: string
  alt?: string
  sx?: SxProps<Theme>
}

interface IState {
  imageSrc: string
}

export default class ImageWithErrorFallback extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { imageSrc: this.getSrc() }
  }

  render() {
    return (
      <Box
        component="img"
        alt={this.props.alt ?? 'lanle-picture'}
        src={this.state.imageSrc}
        onError={this.handleImageError}
        sx={this.props.sx}
      />
    )
  }

  handleImageError = () => this.props.fallbackSrc && this.setState({ imageSrc: this.props.fallbackSrc })

  getSrc = () => {
    const { src, baseUrl } = this.props
    return baseUrl ? baseUrl + src : src
  }
}
