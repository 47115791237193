import { Component } from 'react'
import { Link } from 'react-router-dom'
import { SETTINGS } from './sub'
import { AppConstants } from '../utility'

class Footer extends Component {
  render() {
    return (
      <footer className="main-footer bg-blue banner-bg" data-background="images/footer-blue-bg.png">
        {/*only for mobile-background */}
        <div className="mobile-banner banner-bg" data-background="images/footer-blue-mobile-bg.png" />
        <div className="container">
          {/* footer-top */}
          <div className="footer-top">
            <div className="footer-logo" style={{ height: '96px' }}>
              <a href="#" title="Love Ring">
                <img
                  src={AppConstants.baseImageUrl + 'images/logo-white.svg'}
                  alt="Love Ring"
                  title="Love Ring"
                  style={{ height: '100%' }}
                />
              </a>
            </div>
            <div className="footer-menu">
              <ul>
                {SETTINGS.map((e, i) => (
                  //class acctive: current-menu-item
                  <li key={i + e.name}>
                    <Link to={e.path}>{e.name}</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="footer-social">
              <ul>
                <li>
                  <a href="https://www.facebook.com/lanhairandmakeup" title="facebook" target="_blank">
                    <i className="fab fa-facebook-f" />
                  </a>
                </li>

                <li>
                  <a href="https://www.instagram.com/lanhairandmakeup/" title="instagram" target="_blank">
                    <i className="fab fa-instagram" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* end footer-top */}
          {/* footer-bottom */}
          <div className="footer-bottom">
            <p style={{textTransform: 'uppercase'}}>copyright by weddingsbylan</p>
          </div>
          {/* end footer-bottom */}
          {/* Scroll To Top */}
          <div className="scroll-to-top scroll-to-target" data-target="html">
            <i className="fal fa-long-arrow-up" />
          </div>
        </div>
      </footer>
    )
  }
}
export default Footer
