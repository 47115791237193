import { AppDispatch, RootState } from '../../type'
import { WindowScrollToTop } from '../../../utility'
import { fetchPortfolioThunk } from '../thunks/PortfolioThunk'
import { TDispatchRedux, TStateRedux, hocConnect } from '../hocs/hocConnect'
import PortfolioScreen from '../../../views/Portfolio'
import { PortfolioSliceState } from '../slices/PortfolioSlice'

export interface PortfolioReduxState extends TStateRedux {
  PortfolioSlice: PortfolioSliceState
}

export interface PortfolioReduxDispatch extends TDispatchRedux {}

export interface PortfolioReduxProps extends PortfolioReduxState, PortfolioReduxDispatch {}

const mapStateToProps = (state: RootState): PortfolioReduxState => ({
  Status: state.PortfolioSlice.Status,
  PortfolioSlice: state.PortfolioSlice,
})

const mapDispatchToProps = (dispatch: AppDispatch): PortfolioReduxDispatch => ({
  fetchInitial: () => {
    dispatch(fetchPortfolioThunk())
    return {
      abort() {
        WindowScrollToTop()
      },
    }
  },
})

export const PortfolioRedux = hocConnect(PortfolioScreen, mapStateToProps, mapDispatchToProps)
