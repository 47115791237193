import { IGallery } from '../../../models'
import { AppDispatch, RootState } from '../../type'
import { WindowScrollToTop } from '../../../utility'
import { fetchHomeThunk } from '../thunks/HomeThunk'
import { TDispatchRedux, TStateRedux, hocConnect } from '../hocs/hocConnect'
import HomeScreen from '../../../views/Home'
import { HomeSliceState } from '../slices/HomeSlice'

export interface HomeReduxState extends TStateRedux {
  HomeSlice: HomeSliceState
}

export interface HomeReduxDispatch extends TDispatchRedux {}

export interface HomeReduxProps extends HomeReduxState, HomeReduxDispatch {}

const mapStateToProps = (state: RootState): HomeReduxState => ({
  Status: state.HomeSlice.Status,
  HomeSlice: state.HomeSlice,
})

const mapDispatchToProps = (dispatch: AppDispatch): HomeReduxDispatch => ({
  fetchInitial: () => {
    dispatch(fetchHomeThunk())
    return {
      abort() {
        WindowScrollToTop()
      },
    }
  },
})

export const HomeRedux = hocConnect(HomeScreen, mapStateToProps, mapDispatchToProps)
