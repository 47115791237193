import { createAsyncThunk } from '@reduxjs/toolkit'
import GalleryService from '../../../services/GalleryService'
import { EGallery, IGallery, IInstagram } from '../../../models'
import InstagramService from '../../../services/InstagramService'

const g = [
  EGallery.Carousel,
  EGallery.CarouselMobile,
  EGallery.PhotographyImage,
  EGallery.HomePortfolio,
  EGallery.BackgroundContact,
]

interface IThunkResult {
  Galleries: IGallery[]
  Instagrams: IInstagram[]
}
export const fetchHomeThunk = createAsyncThunk<IThunkResult>('fetchHomeThunk', async (_, thunkAPI) => {
  const reqAll = [GalleryService.All(g, thunkAPI.signal), InstagramService.All(thunkAPI.signal)]
  const [Galleries, igRes] = await Promise.all(reqAll)
  const Instagrams = (igRes as IInstagram[]).filter((e) => e.media_type === 'IMAGE')
  return { Galleries, Instagrams } as IThunkResult
})
