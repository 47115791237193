import React, { Component } from 'react'
import { AppConstants } from '../../utility'
import { IGallery, IInstagram } from '../../models'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { ENavigateKey } from '../../route/NavigateKey'

class FullWidthImageLeftSection extends Component<{ data: IInstagram[] }> {
  render() {
    const path = _.get(this.props.data, '[0].media_url')
    return (
      //Full width Image Section (Left side)
      <section className="section video-section pt-lg-115 pb-lg-115 pt-0 pb-0">
        {path && (
          <div
            className="full-width-img half-left banner-bg wow fadeInUp"
            data-wow-delay="0.3s"
            data-wow-duration="0.5s"
            data-background={path}
          >
            {/* <div className="hero-banner-overlay opacity-30" />
            <a href="#" className="play-btn" data-fancybox="">
              <span>PLAY</span>
            </a> */}
          </div>
        )}
        <div className="container">
          <div className="content-row">
            <div className="offset-lg-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div
                className="content-title tablet-center pl-xxl-125 pl-xl-100 pl-lg-50 pt-lg-0 pt-md-100 pt-60 wow fadeInUp"
                data-wow-delay="0.3s"
                data-wow-duration="0.5s"
              >
                <span className="title-logo">
                  <img src="images/title-logo.png" alt="Lan Le" title="Lan Le" style={{ width: 'auto', height: '47px' }} />
                </span>
                <h2>Makeup and Hair</h2>
                {/* <span className="sub-title">WHO WE ARE</span> */}
                <p className="font-family-gruppo">
                  Get your glam done by me. Event makeup and hair my specialty. Bringing the best of your natural beauty.
                </p>
                <Link className="theme-btn" to={ENavigateKey.MakeupAndHair}>
                  READ MORE
                  <span />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
export default FullWidthImageLeftSection
