import React, { Component } from 'react'
import HeightEqualWidth from '../../components/HeightEqualWidth'
import { IGallery, IInstagram } from '../../models'
import { AppConstants } from '../../utility'
import { Link } from 'react-router-dom'
import { ENavigateKey } from '../../route/NavigateKey'

export default class GridItemsSection extends Component<{ data: IGallery[] }> {
  get data(): IGallery[] {
    const { data = [] } = this.props
    if (data.length <= 0) return []
    return data.slice(0, 3)
  }
  render() {
    return (
      <section className="section blog-section pt-md-60 pt-30 pb-md-30 pb-10">
        <div className="container">
          <div className="content-title center-align mb-md-30 wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.5s">
            <span className="title-logo">
              <img src="images/title-logo.png" alt="Lan Le" title="Lan Le" style={{ width: 'auto', height: '47px' }} />
            </span>
            <h2>Portfolio</h2>
            {/* <span className="sub-title">news</span> */}
          </div>
          <div className="content-row grid-15">
            <div className="row">
              {this.data.map((e, i) => {
                return (
                  <div key={i} className="col-lg-4 col-md-4 col-sm-12 col-12 mb-30">
                    <div className="blog-post center-align wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.5s">
                      <a href="#" className="gallery-item">
                        <HeightEqualWidth ratio={0.927}>
                          <div
                            style={{
                              backgroundImage: `url(${AppConstants.baseUrl + e.ImageUri})`,
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                              width: '100%',
                              height: '100%',
                            }}
                          />
                        </HeightEqualWidth>
                      </a>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Link className="theme-btn" to={ENavigateKey.Portfolio}>
              READ MORE
              <span />
            </Link>
          </div>
        </div>
      </section>
    )
  }
}
