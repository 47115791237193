import _ from 'lodash'
import { EGallery, IGallery } from '../models'
import { AppConstants } from '../utility'
import { CreateHttpService } from './Getway'
import ServiceBase from './Helpers/ServiceBase'

export const GALLERIES_PORTFOLIO = [
  EGallery.GalleryWedding,
  EGallery.GalleryEngagement,
  EGallery.GalleryFamily,
  EGallery.GalleryHairAndMakeup,
  EGallery.PortfolioSlide,
]

class _GalleryService extends ServiceBase {
  constructor() {
    super(CreateHttpService(AppConstants.baseUrl))
  }
  _UrlAll = '/galleries'
  All = async (value?: EGallery[], signal?: AbortSignal) => {
    const filter: any = {
      include: [{ relation: 'ServiceImages' }],
    }
    if (value) filter.where = { Type: { inq: value } }
    const _url = `${this._UrlAll}?filter=${encodeURIComponent(JSON.stringify(filter))}`
    const res = await this.Get(_url, { signal })
    return (res ?? []) as IGallery[]
  }
  Detail = async (galleryId?: string, signal?: AbortSignal) => {
    if (!galleryId) return null
    const filter: any = { where: { Id: galleryId }, include: [{ relation: 'ServiceImages' }] }
    const url = `${this._UrlAll}?filter=${encodeURIComponent(JSON.stringify(filter))}`
    const res: IGallery[] = await this.Get(url, { signal })
    return _.get(res, '[0]', null)
  }
}

const GalleryService = new _GalleryService()
export default GalleryService
