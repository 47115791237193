import { createSlice } from '@reduxjs/toolkit'
import { LazyStatus, TStateRedux } from '../hocs/hocConnect'
import { IGallery, IInstagram, ITopic } from '../../../models'
import { fetchHomeThunk } from '../thunks/HomeThunk'

export interface HomeSliceState extends TStateRedux {
  Galleries: IGallery[]
  Instagrams: IInstagram[]
  Topics: ITopic[]
  requestId?: string
}

// Define the initial state using that type
const initialState: HomeSliceState = {
  Status: LazyStatus.Loading,
  Galleries: [],
  Instagrams: [],
  Topics: [],
}

const HomeSlice = createSlice({
  name: 'HomeSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomeThunk.fulfilled, (state, action) => {
        if(state.requestId !== action.meta.requestId) return
        state.Status = LazyStatus.Loaded
        state.Galleries = action.payload.Galleries
        state.Instagrams = action.payload.Instagrams
        // state.Topics = action.payload.Topics
      })
      .addCase(fetchHomeThunk.rejected, (state, action) => {
        if(state.requestId !== action.meta.requestId) return
        state.Status = LazyStatus.Error
      })
      .addCase(fetchHomeThunk.pending, (state, action) => {
        state.requestId = action.meta.requestId
        state.Status = LazyStatus.Loading
        state.Galleries = []
      })
  },
})

// export const {} = ServiceImageSlice.actions

export default HomeSlice
