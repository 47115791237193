import { IGallery } from '../../../models'
import { AppDispatch, RootState } from '../../type'
import { WindowScrollToTop } from '../../../utility'
import { TDispatchRedux, TStateRedux, hocConnect } from '../hocs/hocConnect'
import GalleryDetailScreen from '../../../views/GalleryDetail'
import { GalleryDetailSliceState } from '../slices/GalleryDetailSlice'

export interface GalleryDetailReduxState extends TStateRedux {
  GalleryDetailSlice: GalleryDetailSliceState
}

export interface GalleryDetailReduxDispatch extends TDispatchRedux {}

export interface GalleryDetailReduxProps extends GalleryDetailReduxState, GalleryDetailReduxDispatch {}

const mapStateToProps = (state: RootState): GalleryDetailReduxState => ({
  Status: state.GalleryDetailSlice.Status,
  GalleryDetailSlice: state.GalleryDetailSlice,
})

const mapDispatchToProps = (dispatch: AppDispatch): GalleryDetailReduxDispatch => ({
  fetchInitial: () => {
    return {
      abort() {
        WindowScrollToTop()
      },
    }
  },
})

export const GalleryDetailRedux = hocConnect(GalleryDetailScreen, mapStateToProps, mapDispatchToProps)
