import React, { Component, Fragment } from 'react'
import { AppConstants } from '../../utility'
import { IDescriptionPrice } from '../../models'
import _ from 'lodash'

interface IProps {
  data: IDescriptionPrice[][]
}
class PriceModuleSection extends Component<IProps> {
  get data(): IDescriptionPrice[][] {
    return this.props.data.map((e) => _.sortBy(e, (elm) => elm.index))
  }

  renderItem = (descriptionPrices: IDescriptionPrice[]) => {
    return descriptionPrices.map((e, i) => (
      <div key={i} className="col-lg-4 col-md-4 col-sm-12 col-12 mb-20">
        <div className="packages-blog wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.5s">
          <div className="packages-blog-title">
            <h3>{e.name}</h3>
            {/* <span className="sk-price">
              $22 <span className="sk-price-meta">/monthly</span>
            </span> */}
          </div>
          <div className="packages-blog-info">
            <ul>
              {e.jsonContent.map((elm, idx) => (
                <li key={idx} className='font-family-gruppo'>{elm}</li>
              ))}
            </ul>
          </div>
          {/* <div className="btn-outer">
            <a href="#" className="theme-btn btn-secondary">
              get started
              <span />
            </a>
          </div> */}
        </div>
      </div>
    ))
  }

  render() {
    return (
      // Price Module section
      <section className="section price-module-section pt-md-60 pt-30 pb-md-35 pb-10">
        <div className="container">
          <div className="content-title center-align mb-md-30 wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.5s">
            <span className="title-logo">
              <img
                src={AppConstants.baseImageUrl + 'images/title-logo.png'}
                alt="Love Ring"
                title="Love Ring"
                style={{ height: AppConstants.heightLogoTitle, width: 'auto' }}
              />
            </span>
            <h2>Services</h2>
            <span className="sub-title">Choose Yours</span>
          </div>
          <div className="content-row grid-15">
            {this.data.map((e, i) => (
              <Fragment key={i}>
                {i > 0 && <div style={{ width: '100%', borderBottom: '1px solid rgba(0,0,0,0.1)', margin: '24px 0' }} />}
                <div className="row">{this.renderItem(e)}</div>
              </Fragment>
            ))}
          </div>
        </div>
      </section>
    )
  }
}

export default PriceModuleSection
