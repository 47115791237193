import React, { Component } from 'react'

export class SideNav extends Component {
  render() {
    return (
      // Side nav
      <div className="sidenav">
        <div className="close-sidenav-outer">
          <a href="#" className="close-sidenav">
            <i className="fal fa-times" />
          </a>
        </div>
        <a href="index.html" className="sidenav-logo" title="Love ring">
          <img src="images/logo.png" alt="Love ring" title="Love ring" width={200} height={47} />
        </a>
        <span className="sub-title">WE ARE GETTING MARRIED APRIL 06, 2023</span>
        <div className="sidenav-insta-grid">
          <label>#lovering</label>
          <a href="#" className="gallery-item">
            <img src="images/instagram-photo-1.png" alt="instagram-photo" />
            <div className="gallery-hover">
              <span>
                <i className="fab fa-instagram" />
              </span>
            </div>
          </a>
          <a href="#" className="gallery-item">
            <img src="images/instagram-photo-2.png" alt="instagram-photo" />
            <div className="gallery-hover">
              <span>
                <i className="fab fa-instagram" />
              </span>
            </div>
          </a>
          <a href="#" className="gallery-item">
            <img src="images/instagram-photo-3.png" alt="instagram-photo" />
            <div className="gallery-hover">
              <span>
                <i className="fab fa-instagram" />
              </span>
            </div>
          </a>
          <a href="#" className="gallery-item">
            <img src="images/instagram-photo-4.png" alt="instagram-photo" />
            <div className="gallery-hover">
              <span>
                <i className="fab fa-instagram" />
              </span>
            </div>
          </a>
          <a href="#" className="gallery-item">
            <img src="images/instagram-photo-5.png" alt="instagram-photo" />
            <div className="gallery-hover">
              <span>
                <i className="fab fa-instagram" />
              </span>
            </div>
          </a>
          <a href="#" className="gallery-item">
            <img src="images/instagram-photo-6.png" alt="instagram-photo" />
            <div className="gallery-hover">
              <span>
                <i className="fab fa-instagram" />
              </span>
            </div>
          </a>
        </div>
        <div className="sidenav-bottom">
          <p>
            Sunday, 05 Nov. 2023
            <br />
            2:00 PM – 3:30 PM
            <br />
            Mas Montagnette,
            <br />
            19 West 21th Str. NY
            <br />
            +1 843-853-1810
          </p>
          <a href="#" className="load-more">
            vıew map
          </a>
          <ul>
            <li>
              <a href="#" title="facebook">
                <i className="fab fa-facebook-f" />
              </a>
            </li>
            <li>
              <a href="#" title="twitter">
                <i className="fab fa-twitter" />
              </a>
            </li>
            <li>
              <a href="#" title="instagram">
                <i className="fab fa-instagram" />
              </a>
            </li>
            <li>
              <a href="#" title="linkedin-in">
                <i className="fab fa-linkedin-in" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default SideNav
