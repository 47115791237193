import React, { Component, Fragment } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import BannerSection from './BannerSection'
import IconBoxSection from './IconBoxSection'
import GallerySection from './GallerySection'
import TestimonialsSection from './TestimonialsSection'
import ServiceSection from './ServiceSection'
import CTASection from './CTASection'
import PriceModuleSection from '../../components/PriceModuleSection'
import BlogSection from './BlogSection'
import TeamSection from './TeamSection'
import ContactFormSection from './ContactFormSection'
import AddressBoxSection from './AddressBoxSection'
import InstagramGridSection from './InstagramGridSection'
import SideNav from '../../components/SideNav'
import { HomeReduxProps } from '../../redux/reducers/maps/HomeRedux'
import { EGallery, ETopic, IServiceImage } from '../../models'
import _ from 'lodash'
import FullWidthImageLeftSection from './FullWidthImageLeftSection'
import FullWidthImageRightSection from './FullWidthImageRightSection'
import GridItemsSection from './GridItemsSection'

interface IProps extends HomeReduxProps {}

export default class HomeScreen extends Component<IProps> {
  getImageFromGallery = (galleryType: EGallery) => {
    const gallery = this.props.HomeSlice.Galleries.find((e) => e.Type === galleryType)
    return _.get(gallery, 'ServiceImages', []) as IServiceImage[]
  }
  getTopicByEnum = (key: ETopic) => {
    return this.props.HomeSlice.Topics.find((e) => e.Name === key)
  }
  render() {
    const topic = this.getTopicByEnum(ETopic.Home)
    return (
      <Fragment>
        {/* <SideNav /> */}
        <div className="page-wrapper">
          <div className="body-cover" />
          <div className="preloader" />
          <Header />
          <main>
            <BannerSection
              data={this.getImageFromGallery(EGallery.Carousel)}
              mobileData={this.getImageFromGallery(EGallery.CarouselMobile)}
            />
            <div style={{ height: '115px' }} />
            {/* <IconBoxSection /> */}
            <FullWidthImageRightSection data={this.getImageFromGallery(EGallery.PhotographyImage)} />
            {/* <GallerySection data={this.getImageFromGallery(EGallery.Gallery)} /> */}
            {/* <TestimonialsSection /> */}
            <div style={{ height: '115px' }} />
            <FullWidthImageLeftSection data={this.props.HomeSlice.Instagrams} />
            <GridItemsSection data={this.getImageFromGallery(EGallery.HomePortfolio)} />
            <div style={{ height: '115px' }} />
            {/* <ServiceSection /> */}
            {/* <CTASection /> */}
            {/* <ProductSection />/ */}
            {/* <PriceModuleSection /> */}
            {/* {topic && <BlogSection data={topic} />} */}
            {/* <TeamSection data={this.getImageFromGallery(EGallery.OurTeam)} /> */}
            <ContactFormSection data={this.getImageFromGallery(EGallery.BackgroundContact)} />
            {/* <AddressBoxSection /> */}
            {/* <LogoGridSection /> */}
            {/* <InstagramGridSection data={this.props.HomeSlice.Instagrams} /> */}
          </main>
          <Footer />
        </div>
      </Fragment>
    )
  }
}
