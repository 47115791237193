import { createAsyncThunk } from '@reduxjs/toolkit'
import { EGallery, EPackage, IGallery, IInstagram, IPrice } from '../../../models'
import PriceService from '../../../services/PriceService'
import InstagramService from '../../../services/InstagramService'
import GalleryService from '../../../services/GalleryService'

const g = [EGallery.MakeupAndHairAbout]
const p = [EPackage.MakeupAndHair1, EPackage.MakeupAndHair2, EPackage.MakeupAndHairMore]

interface IThunkResult {
  Instagrams: IInstagram[]
  Prices: IPrice[]
  Galleries: IGallery[]
}
export const fetchMakeupAndHairThunk = createAsyncThunk<IThunkResult>('fetchMakeupAndHairThunk', async (_, thunkAPI) => {
  const resAll = [
    PriceService.All(p, thunkAPI.signal),
    GalleryService.All(g, thunkAPI.signal),
    InstagramService.All(thunkAPI.signal),
  ]
  const [Prices, Galleries, Instagrams] = await Promise.all(resAll)
  return { Instagrams, Prices, Galleries } as IThunkResult
})
