import React, { Component } from 'react'
import { IGallery } from '../../models'
import _ from 'lodash'
import { AppConstants } from '../../utility'
import { Link } from 'react-router-dom'
import { ENavigateKey } from '../../route/NavigateKey'

class FullWidthImageRightSection extends Component<{ data: IGallery[] }> {
  render() {
    const path = _.get(this.props.data, '[0].ImageUri')
    return (
      // Full width Image Section (Right side)
      <section className="section video-section pt-lg-115 pb-lg-115 pt-0 pb-0">
        {path && (
          <div
            className="full-width-img half-right banner-bg wow fadeInUp"
            data-wow-delay="0.3s"
            data-wow-duration="0.5s"
            data-background={AppConstants.baseUrl + path}
          />
        )}

        <div className="container">
          <div className="content-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div
                className="content-title tablet-center pr-xxl-125 pr-xl-100 pr-lg-50 pt-lg-0 pt-md-100 pt-60 wow fadeInUp"
                data-wow-delay="0.3s"
                data-wow-duration="0.5s"
              >
                <span className="title-logo">
                  <img src="images/title-logo.png" alt="Lan Le" title="Lan Le" style={{ width: 'auto', height: '47px' }} />
                </span>
                <h2>Photography</h2>
                {/* <span className="sub-title">service</span> */}
                <p className="font-family-gruppo">
                  Our approach to documenting life's greatest memories is authentic and intentional. We believe that best photos
                  are created the way you fall in love - naturally. Let us take you on this journey and guide you through every
                  step of the way.
                </p>
                <Link className="theme-btn" to={ENavigateKey.Photography}>
                  READ MORE
                  <span />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
export default FullWidthImageRightSection
