import React, { Component, FC } from 'react'
import { AppConstants } from '../utility'
import { useNavigate } from 'react-router-dom'
import { ENavigateKey } from '../route/NavigateKey'
import { Link } from 'react-router-dom'
import { SETTINGS } from './sub'

const Header: FC = () => {
  // const phoneNumber = '012 345 678 910'
  const imgUrl = AppConstants.baseImageUrl + 'images/logo.png'
  return (
    <header>
      {/* Header Span */}
      <span className="header-span" />
      <div className="main-header">
        {/* desktop-header */}
        <div className="desktop-header">
          <div className="container">
            <div className="header-inner">
              <div className="header-left">
                {/* <a href="javascript:void(0)" className="menu-icon sidenav-menu-icon">
                  <span className="line" />
                  <span className="line" />
                </a> */}
                <Link to="/" className="header-logo" title="Love ring">
                  <img src={imgUrl} alt="Love ring" title="Love ring" width={200} height={47} />
                </Link>
              </div>
              <div className="header-center">
                <nav className="nav main-menu">
                  <ul className="navigation" id="navbar">
                    {SETTINGS.map((e, i) => (
                      //class acctive: current-menu-item
                      <li key={i + e.name}>
                        <Link to={e.path}>{e.name}</Link>
                      </li>
                    ))}

                    {/* Only for Mobile View */}
                    {/* <li className="mm-add-listing">
                      <a href="javascript:void(0)" className="theme-btn">
                        Pricing & Availability
                        <span />
                      </a>
                    </li> */}
                  </ul>
                </nav>
              </div>
              {/* <div className="header-right">
                <a href="javascript:void(0)" className="theme-btn">
                  Pricing & Availability
                  <span />
                </a>
              </div> */}
            </div>
          </div>
        </div>
        {/* end desktop-header */}
        {/* mobile-header */}
        <div className="mobile-header">
          <div className="container">
            <div className="header-inner">
              <div className="header-left">
                <a href="index.html" className="header-logo" title="Love ring">
                  <img src={imgUrl} alt="Love ring" title="Love ring" width={200} height={47} />
                </a>
              </div>
              <div className="header-center"></div>
              <div className="header-right">
                <ul className="header-cart-list">
                  <li>
                    <a href="#nav-mobile" className="menu-icon">
                      <span className="line" />
                      <span className="line" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* end-mobile-header */}
        {/* Mobile Nav */}
        <div id="nav-mobile" />
        {/* header search popup */}
        <div className="search-bar">
          <a href="javascript:void(0)" className="search-close">
            <i className="fal fa-times" />
          </a>
          <div className="container">
            <div className="search-bar-content">
              <div className="search-bar-info">
                <input type="text" name="text" placeholder="SEARCH PRODUCTS..." />
                <button type="submit">
                  <img src="images/search-white.svg" alt="submit-btn" title="submit-btn" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
