import { createSlice } from '@reduxjs/toolkit'
import { LazyStatus, TStateRedux } from '../hocs/hocConnect'
import { fetchPortfolioThunk } from '../thunks/PortfolioThunk'
import { IGallery } from '../../../models'

export interface PortfolioSliceState extends TStateRedux {
  Galleries: IGallery[]
}

// Define the initial state using that type
const initialState: PortfolioSliceState = {
  Status: LazyStatus.Loading,
  Galleries: []
}

const PortfolioSlice = createSlice({
  name: 'PortfolioSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPortfolioThunk.fulfilled, (state, action) => {
        state.Status = LazyStatus.Loaded
        state.Galleries = action.payload.Galleries
      })
      .addCase(fetchPortfolioThunk.rejected, (state) => {
        state.Status = LazyStatus.Error
      })
      .addCase(fetchPortfolioThunk.pending, (state) => {
        state.Status = LazyStatus.Loading
      })
  },
})

// export const {} = ServiceImageSlice.actions

export default PortfolioSlice
