import React, { Component } from 'react'
import { AppConstants } from '../../utility'
import { IGallery } from '../../models'
import _ from 'lodash'

export class ContactFormSection extends Component<{ data: IGallery[] }> {
  render() {
    const path = _.get(this.props.data, '[0].ImageUri')
    return (
      // Contact Form Section
      <section className="section contact-section z-index-2 pt-200 pb-md-60 pb-30">
        {path && <div className="contact-bg banner-bg" data-background={AppConstants.baseUrl + path} />}
        <div className="container">
          <div className="content-row">
            <div className="max-width-1070">
              <div className="contact-form wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.5s">
                <span className="contact-form-vector">
                  <img src={AppConstants.baseImageUrl + 'images/vector-leaf.svg'} alt="leaf-icon" title="leaf-icon" />
                </span>
                {/* <div className="content-title mb-30 center-align">
                  <span className="top-title">let’s meet</span>
                  <h2>MAKE AN INQUIRY</h2>
                </div>
                <div className="input-div">
                  <input type="text" name="name" placeholder="full name" />
                </div>
                <div className="input-div">
                  <input type="email" name="Email" placeholder="Email" />
                </div>
                <div className="input-div">
                  <input type="text" name="address" placeholder="address" />
                </div>
                <div className="input-div chosen-search">
                  <select className="chosen-search-select">
                    <option>services</option>
                    <option>WEDDING PLANNING</option>
                    <option>PARTIAL PLANNING</option>
                    <option>EVENT PLANNING</option>
                    <option>FULL SERVICE</option>
                  </select>
                </div>
                <div className="input-div chosen-search">
                  <select className="chosen-search-select">
                    <option>number of guest</option>
                    <option>100</option>
                    <option>150</option>
                    <option>300</option>
                    <option>500</option>
                  </select>
                </div>
                <div className="input-div chosen-search">
                  <select className="chosen-search-select">
                    <option>MEAL PREFERENCES</option>
                    <option>Sweet</option>
                    <option>savory</option>
                    <option>salty</option>
                  </select>
                </div>
                <div className="input-div">
                  <button type="submit" className="theme-btn full-width">
                    SEND AN INQUIRY
                    <span />
                  </button>
                </div> */}
                <iframe
                  src="https://docs.google.com/forms/d/e/1FAIpQLSdXU555oHs0BlnCtL4woinpA8u77nR2yrhl3ITeyK3UhWyO9w/viewform?embedded=true"
                  width="100%"
                  height="1750"
                  title='form-google'
                  {...{ frameborder: '0', marginheight: '0', marginwidth: '0' }}
                >
                  Loading…
                </iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default ContactFormSection
