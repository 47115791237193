import { createBrowserRouter } from 'react-router-dom'
import { ENavigateKey } from './NavigateKey'
import { HomeRedux } from '../redux/reducers/maps/HomeRedux'
import BlogSingle from '../views/BlogSingle'
import { PhotographyRedux } from '../redux/reducers/maps/PhotographyRedux'
import { MakeupAndHairRedux } from '../redux/reducers/maps/MakeupAndHairRedux'
import { PortfolioRedux } from '../redux/reducers/maps/PortfolioRedux'
import { ContactUsRedux } from '../redux/reducers/maps/ContactUsRedux'
import { GalleryDetailRedux } from '../redux/reducers/maps/GalleryDetailRedux'

export const router = createBrowserRouter(
  [
    {
      path: ENavigateKey.Home,
      element: <HomeRedux />,
    },
    {
      path: ENavigateKey.BlogSignle,
      element: <BlogSingle />,
    },
    {
      path: ENavigateKey.Photography,
      element: <PhotographyRedux />,
    },
    {
      path: ENavigateKey.MakeupAndHair,
      element: <MakeupAndHairRedux />,
    },
    {
      path: ENavigateKey.Portfolio,
      element: <PortfolioRedux />,
    },
    {
      path: ENavigateKey.ContactUs,
      element: <ContactUsRedux />,
    },
    {
      path: ENavigateKey.GalleryDetail,
      element: <GalleryDetailRedux />,
    },
  ],
  { basename: '/app' }
)
