import { AppConstants } from '../utility'
import { EPackage, IPrice } from '../models'
import { CreateHttpService } from './Getway'
import ServiceBase from './Helpers/ServiceBase'

class _PriceService extends ServiceBase {
  constructor() {
    super(CreateHttpService(AppConstants.baseUrl))
  }
  _UrlAll = '/prices'
  All = async (packs: EPackage[], signal?: AbortSignal) => {
    const filter = { where: { Name: { inq: packs } } }
    const _url = `${this._UrlAll}?filter=${encodeURIComponent(JSON.stringify(filter))}`
    const res = await this.Get<any>(_url, { signal })
    return res as IPrice[]
  }
}

const PriceService = new _PriceService()
export default PriceService
