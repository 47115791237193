import { Box, SxProps, Theme } from "@mui/material";
import { FC, ReactNode, useEffect, useRef, useState } from "react";

interface IProps {
  sx?: SxProps<Theme>;
  children?: ReactNode;
  className?: string;
  ratio?: number;
  renderChildren?: (height: number) => ReactNode;
}

export const HeightEqualWidth: FC<IProps> = (props) => {
  const boxRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  useEffect(() => {
    const setHeightToWidth = () => {
      if (boxRef.current) {
        const { ratio = 1 } = props;
        const newHeight = boxRef.current.offsetWidth * ratio;
        boxRef.current.style.height = `${newHeight}px`;
        setHeight(newHeight);
      }
    };
    setHeightToWidth();
    window.addEventListener("resize", setHeightToWidth);
    return () => {
      window.removeEventListener("resize", setHeightToWidth);
    };
  }, []);

  const _renderChildren = () => {
    const { renderChildren = () => <></> } = props;
    return renderChildren(height);
  };

  const { children = _renderChildren() } = props;

  return (
    <Box
      {...props}
      ref={boxRef}
      sx={{
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          ...props.sx,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default HeightEqualWidth;
