import { createAsyncThunk } from '@reduxjs/toolkit'
import PriceService from '../../../services/PriceService'
import { EGallery, EPackage, IGallery, IPrice } from '../../../models'
import GalleryService from '../../../services/GalleryService'

const g = [EGallery.PhotographyAbout, EGallery.PhotographySlide]
interface IThunkResult {
  Prices: IPrice[]
  Galleries: IGallery[]
}
export const fetchPhotographyThunk = createAsyncThunk<IThunkResult>('fetchPhotographyThunk', async (_, thunkAPI) => {
  const resAll = [GalleryService.All(g, thunkAPI.signal), PriceService.All([EPackage.Photography], thunkAPI.signal)]
  const [Galleries, Prices] = await Promise.all(resAll)
  return { Prices, Galleries } as IThunkResult
})
