import { Box, Stack, Typography } from '@mui/material'
import React, { Component } from 'react'

export default class ErrorsScreen extends Component {
  render() {
    return (
      <Stack
        sx={{
          height: '100vh',
          alignItems: 'center',
        }}
      >
        <Box height="100%" />
        <Typography variant="h1">Errors Something</Typography>
        <Typography variant="subtitle1">Please try again!</Typography>
        <Box height="100%" />
      </Stack>
    )
  }
}
