import { loadScript } from '../Helper'

export const AppConstants = {
  // baseUrl: 'https://partner.dinocollab.com:30080',
  baseUrl: 'https://weddingsbylan.com/',
  baseImageUrl: '',
  typeFormId: 'QRjh1jju',
  heightLogoTitle: '47px',
}

export const Sleep = (sec: number) => {
  return new Promise((resolve) => setTimeout(resolve, sec))
}

export const FetchDelay = async function <TModel>(action: () => Promise<TModel>, sec: number) {
  const [res] = await Promise.all([action(), Sleep(sec)])
  return res
}

export const WindowScrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
}

export const scriptLinks: string[] = [
  '/js/jquery.min.js',
  '/plugin/bootstrap/popper.min.js',
  '/plugin/chosen/chosen.min.js',
  '/plugin/bootstrap/bootstrap.min.js',
  '/plugin/fancybox/jquery.fancybox.min.js',
  '/plugin/mmenu/mmenu.polyfills.js',
  '/plugin/mmenu/mmenu.js',
  '/plugin/mscroll/jquery.mCustomScrollbar.concat.min.js',
  '/plugin/bootstrap/appear.js',
  '/plugin/owl/owl.carousel.min.js',
  '/plugin/owl/owl.carousel2.thumbs.min.js',
  '/plugin/isotope/isotope.min.js',
  '/plugin/stellar/jquery.stellar.min.js',
  '/plugin/wow/wow.min.js',
  '/js/script.js',
]
export const loadScriptFunc = (links: string[]) => {
  links.forEach((url) => loadScript(process.env.PUBLIC_URL + url, false, ''))
}
