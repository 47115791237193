import { Component, Fragment } from 'react'
import { AppConstants } from '../../utility'
import { PhotographyReduxProps } from '../../redux/reducers/maps/PhotographyRedux'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import SideNav from '../../components/SideNav'
import PriceModuleSection from './PriceModuleSection'
import { EGallery, IDescriptionPrice, IServiceImage } from '../../models'
import _ from 'lodash'
import { Collapse, styled } from '@mui/material'

interface IProps extends PhotographyReduxProps {}
interface IState {
  isShowMore: boolean
}
export default class PhotographyScreen extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { isShowMore: false }
  }
  get serviceData(): IDescriptionPrice[][] {
    let data: IDescriptionPrice[][] = []
    const { Prices } = this.props.PhotographySlice
    if (Prices.length > 0) {
      Prices.forEach((e) => {
        data.push(JSON.parse(e.Description))
      })
    }
    return data
  }
  getImageFromGallery = (galleryType: EGallery) => {
    const gallery = this.props.PhotographySlice.Galleries.find((e) => e.Type === galleryType)
    return _.get(gallery, 'ServiceImages', []) as IServiceImage[]
  }
  render() {
    const avatarPath = _.get(this.getImageFromGallery(EGallery.PhotographyAbout), '[0].ImageUri')
    const slide = this.getImageFromGallery(EGallery.PhotographySlide)
    return (
      <Fragment>
        {/* header sidenav  */}

        <SideNav />

        <div className="page-wrapper">
          <div className="body-cover"></div>

          {/* Preloader */}
          <div className="preloader"></div>

          {/* Main Header */}
          <Header />
          {/* End Main Header */}

          <main>
            {/* wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.5s */}

            {/* Gallery section */}
            <section className="section overflow-hidden pt-md-100 pt-45">
              <div className="container">
                <div
                  className="content-row portfolio-fullscreen-slider content-bottom wow fadeInUp"
                  data-wow-delay="0.3s"
                  data-wow-duration="0.5s"
                >
                  <div className="row no-gutters">
                    <div
                      className="col-lg-5 order-lg-first order-last col-md-12 col-sm-12 col-12"
                      style={{ marginRight: '-48px' }}
                    >
                      <div className="content-title mb-30 wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.5s">
                        <span className="top-title">Photography</span>
                        <h2 style={{ textTransform: 'uppercase' }}>Capturing the moments that really matter</h2>
                        <p style={{ maxWidth: '85%' }} className='font-family-gruppo'>
                          Our approach to documenting life's greatest memories is authentic and intentional. We believe that best
                          photos are created the way you fall in love - naturally. Let us take you on this journey and guide you
                          through every step of the way.
                        </p>
                        {/* <a href="#" className="load-more">
                          read more
                        </a> */}
                      </div>
                    </div>
                    <div className="col-lg-7 order-lg-last order-first col-md-12 col-sm-12 col-12">
                      <div className="portfolio-fullscreen-slider-outer">
                        <div className="portfolio-carousel-two owl-carousel owl-theme">
                          {slide.map((e, i) => (
                            <div className="item" key={i}>
                              <a href={AppConstants.baseUrl + e.ImageUri} className="gallery-item" data-fancybox="gallery">
                                <CustomImage ratio={0.859}>
                                  <img
                                    src={AppConstants.baseUrl + e.ImageUri}
                                    alt="photography-picture"
                                    title="photography-picture"
                                  />
                                </CustomImage>
                                <div className="gallery-hover">
                                  <span className="gallery-popup">+</span>
                                </div>
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* End Gallery section */}

            {/* Image Section (Left side) */}
            <section className="section pb-md-60 pb-30">
              <div className="container">
                <div className="content-row content-middle">
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="content-img wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.5s">
                        <img src={AppConstants.baseUrl + avatarPath} alt="wedding-photo" title="wedding-photo" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div
                        className="content-title tablet-center pl-xxl-100 pl-xl-75 pl-lg-50 pt-lg-0 pt-md-100 pt-60 wow fadeInUp"
                        data-wow-delay="0.3s"
                        data-wow-duration="0.5s"
                      >
                        <span className="title-logo">
                          <img
                            src="images/title-logo.png"
                            alt="Love Ring"
                            title="Love Ring"
                            style={{ height: AppConstants.heightLogoTitle, width: 'auto' }}
                          />
                        </span>
                        <h2>About Lan Le</h2>
                        <p className='font-family-gruppo'>
                          Lan Le is a talented and passionate photographer who is dedicated to capturing the special moments of
                          couples, families, and weddings. With an eye for detail and a creative flair, she is able to capture
                          stunning and unique images that truly reflect the essence of her subjects. Her approach to photography
                          is simple yet effective: she seeks to capture the natural beauty and authenticity of her couples,
                          without any artificial posing or staging.
                          <Collapse in={this.state.isShowMore} unmountOnExit>
                            <span>
                              Instead, she encourages her clients to simply be themselves and to let their personalities shine
                              through. For Lan, the most rewarding part of her work is seeing the joy and happiness on her
                              clients' faces when they see their photos for the first time. She takes great pride in knowing that
                              she has helped to create lasting memories that will be cherished for years to come. Whether she is
                              photographing a couple's engagement, a family's portrait, or a wedding celebration, she always puts
                              her heart and soul into her work. Her passion for photography is infectious, and she has a way of
                              making her clients feel comfortable and at ease in front of the camera. Overall, she is a talented
                              and dedicated photographer who has a true gift for capturing the special moments that make life so
                              beautiful. Her clients can rest assured that they are in good hands, and that their photos will be
                              nothing short of stunning.
                            </span>
                          </Collapse>
                        </p>
                        <span
                          className="sub-title"
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.setState((prev) => ({ isShowMore: !prev.isShowMore }))}
                        >
                          {this.state.isShowMore ? 'Show Less' : 'Read More'}
                        </span>
                        {/* <a href="#" className="theme-btn">
                          READ MORE
                          <span />
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* End Image Section (Left side) */}

            <PriceModuleSection data={this.serviceData} />
          </main>

          {/* Main Footer */}
          <Footer />
          {/* End Main Footer */}
        </div>
      </Fragment>
    )
  }
}

const CustomImage = styled('div')<{ ratio: number }>(({ ratio }) => ({
  width: '100%',
  paddingTop: `${100 * ratio}%`,
  position: 'relative',
  cursor: 'pointer',
  '& img': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}))
