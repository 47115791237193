import React, { Component } from 'react'
import { IGallery } from '../../models'
import _ from 'lodash'
import { AppConstants } from '../../utility'

interface IProps {
  data: IGallery[]
  mobileData: IGallery[]
}
class BannerSection extends Component<IProps> {
  get data(): IGallery[] {
    return _.sortBy(this.props.data, (e) => e.Title)
  }
  getImageMobile = (index: number) => {
    const df = 'images/hero-banner-mobile1.png'
    const uri = _.get(this.props.mobileData[index], 'ImageUri', df)
    if (uri === df) return df
    return AppConstants.baseUrl + uri
  }
  render() {
    return (
      // wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.5s
      <section className="banner-section">
        <div className="banner-sidebar">
          <a href="#" className="scroll-to scroll-to-target" data-target="#reach-to">
            Scroll to discover
          </a>
          <a href="mailto:lanhairandmakeup@gmail.com" target='_blank' className="banner-sidebar-link">
            lanhairandmakeup@gmail.com
          </a>
          <ul className="sidebar-social">
            <li>
              <a href="https://www.facebook.com/lanhairandmakeup" target="_blank">
                <i className="fab fa-facebook-f" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/lanhairandmakeup/" target="_blank">
                <i className="fab fa-instagram" />
              </a>
            </li>
          </ul>
        </div>
        <div className="banner-carousel owl-carousel owl-theme">
          {this.data.map((e, i) => (
            <div key={e.Title} className="slide-item banner-bg" data-background={AppConstants.baseUrl + e.ImageUri}>
              <div className="mobile-banner banner-bg" data-background={this.getImageMobile(i)} />
              <div className="hero-banner-overlay" />
              <div className="container">
                {/* <div className="hero-banner-caption">
                  <h1>Luxury Weddings &amp; Parties</h1>
                  <p>
                    Lorem ipsum dolor sit amet leo consectetuer adipiscing elit commodo Lorem ipsum dolor sit amet leo
                    consectetuer
                  </p>
                  <a href="#" className="theme-btn">
                    READ MORE
                    <span />
                  </a>
                </div> */}
              </div>
            </div>
          ))}
        </div>
      </section>
    )
  }
}
export default BannerSection
