import { IGallery } from '../../../models'
import { AppDispatch, RootState } from '../../type'
import { WindowScrollToTop } from '../../../utility'
import { fetchContactUsThunk } from '../thunks/ContactUsThunk'
import { TDispatchRedux, TStateRedux, hocConnect } from '../hocs/hocConnect'
import ContactUsScreen from '../../../views/ContactUs'
import { ContactUsSliceState } from '../slices/ContactUsSlice'

export interface ContactUsReduxState extends TStateRedux {
  ContactUsSlice: ContactUsSliceState
}

export interface ContactUsReduxDispatch extends TDispatchRedux {}

export interface ContactUsReduxProps extends ContactUsReduxState, ContactUsReduxDispatch {}

const mapStateToProps = (state: RootState): ContactUsReduxState => ({
  Status: state.ContactUsSlice.Status,
  ContactUsSlice: state.ContactUsSlice,
})

const mapDispatchToProps = (dispatch: AppDispatch): ContactUsReduxDispatch => ({
  fetchInitial: () => {
    dispatch(fetchContactUsThunk())
    return {
      abort() {
        WindowScrollToTop()
      },
    }
  },
})

export const ContactUsRedux = hocConnect(ContactUsScreen, mapStateToProps, mapDispatchToProps)
