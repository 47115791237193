import { ENavigateKey } from '../route/NavigateKey'

interface ISettings {
  name: string
  path: string
}
export const SETTINGS: ISettings[] = [
  { name: 'Home', path: ENavigateKey.Home },
  { name: 'Photography', path: ENavigateKey.Photography },
  { name: 'Makeup & Hair', path: ENavigateKey.MakeupAndHair },
  { name: 'Portfolio', path: ENavigateKey.Portfolio },
  { name: 'Contacts Us', path: ENavigateKey.ContactUs },
]
