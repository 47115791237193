import { Component, ComponentType } from 'react'
import { connect, ConnectedComponent } from 'react-redux'
import { AppDispatch, RootState } from '../../type'
import { LazyLoadView } from '../../../components/LazyLoadView'
import ErrorsScreen from '../../../components/ErrorsScreen'
import { loadScriptFunc, scriptLinks } from '../../../utility'

export enum LazyStatus {
  Loading = 'Loading',
  Loaded = 'Loaded',
  Error = 'Error',
}

interface IReturnDispatch {
  abort: () => void
}

type FetchAction<TParam> = (
  p: TParam
  // token?: CancelToken
) => IReturnDispatch

export interface TDispatchRedux<TParam = any> {
  fetchInitial: FetchAction<Partial<TParam>>
}

export interface TStateRedux {
  Status: LazyStatus
}

type ActionMapStateToProps<TState> = (state: RootState) => TState
type ActionMapDispatchToProps<TProp> = (dispatch: AppDispatch, props?: any) => TProp

interface hocComponentProp<TActionParam> {
  params?: TActionParam
}

interface OptionsHocLazy<TActionParam> {
  params?: TActionParam
}

export const hocConnect = function <
  TActionParam,
  TMapState extends TStateRedux,
  TMapDispatch extends TDispatchRedux<TActionParam> = TDispatchRedux<TActionParam>,
  TComponentProp = any
>(
  WrappedComponent: ComponentType<any>,
  actionState: ActionMapStateToProps<TMapState> | null = null,
  actionProp: ActionMapDispatchToProps<TMapDispatch> | null = null,
  options: OptionsHocLazy<TActionParam> | null = null
) {
  type Props = hocComponentProp<TActionParam> & TMapState & TMapDispatch & TComponentProp
  class HocComponent extends Component<Props> {
    renderContent = () => {
      switch (this.props.Status) {
        case LazyStatus.Loaded:
        case LazyStatus.Loading:
          return (
            <LazyLoadView
              IsLazy={this.props.Status === LazyStatus.Loading}
              showProgress
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <WrappedComponent {...this.props} />
            </LazyLoadView>
          )
        // return <WrappedComponent {...this.props} />;
        case LazyStatus.Error:
          return <ErrorsScreen />
        default:
          return <ErrorsScreen />
      }
    }

    componentDidMount = () => {
      const param = Object.assign({}, options?.params ?? {}, this.props.params ?? {})
      this.DispatchReturn = this.props.fetchInitial ? this.props.fetchInitial(param) : undefined
    }
    DispatchReturn?: IReturnDispatch
    componentWillUnmount() {
      this.DispatchReturn?.abort()
    }
    componentDidUpdate(): void {
      if (this.props.Status === LazyStatus.Loaded) loadScriptFunc(scriptLinks)
    }
    render = () => {
      return this.renderContent()
    }
  }
  const CustomActionProp = (dispatch: AppDispatch) => {
    return {
      ...(actionProp ? actionProp(dispatch) : {}),
    }
  }
  const Comp = connect(actionState, CustomActionProp, null, {
    forwardRef: true,
  })(HocComponent as any)
  return Comp as ConnectedComponent<ComponentType<TComponentProp>, TComponentProp>
}
