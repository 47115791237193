import { EntityState, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { LazyStatus, TStateRedux } from '../hocs/hocConnect'
import { fetchGalleryDetailThunk } from '../thunks/GalleryDetailThunk'
import { IGallery, IServiceImageDTO } from '../../../models'
import { parseDesctionData } from '../../../helpers'

export interface GalleryDetailSliceState extends TStateRedux {
  requestId?: string
  Gallery: IGallery | null
  images: EntityState<IServiceImageDTO>
}

const ImagesAdapter = createEntityAdapter<IServiceImageDTO>({ selectId: (x) => x.Id })

// Define the initial state using that type
const initialState: GalleryDetailSliceState = {
  Status: LazyStatus.Loading,
  Gallery: null,
  images: ImagesAdapter.getInitialState(),
}

const GalleryDetailSlice = createSlice({
  name: 'GalleryDetailSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGalleryDetailThunk.fulfilled, (state, action) => {
        if (state.requestId !== action.meta.requestId) return
        state.Status = LazyStatus.Loaded
        state.Gallery = action.payload
        ImagesAdapter.removeAll(state.images as EntityState<IServiceImageDTO>)
        const images = (action.payload?.ServiceImages ?? []).map((elm, index) => ({
          ...elm,
          Description: parseDesctionData(elm.Description) ?? { index },
        }))
        ImagesAdapter.upsertMany(state.images, images)
      })
      .addCase(fetchGalleryDetailThunk.rejected, (state, action) => {
        if (state.requestId !== action.meta.requestId) return
        state.Status = LazyStatus.Error
      })
      .addCase(fetchGalleryDetailThunk.pending, (state, action) => {
        state.requestId = action.meta.requestId
        state.Status = LazyStatus.Loading
      })
  },
})

// export const {} = ServiceImageSlice.actions

export default GalleryDetailSlice
