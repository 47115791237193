import React, { Component, Fragment } from 'react'
import { AppConstants } from '../../utility'
import { EPackage, IDescriptionPrice, IPrice } from '../../models'
import _ from 'lodash'

interface IConfig {
  lg?: number
  md?: number
  sm?: number
  xs?: number
}

interface IProps {
  data: IPrice[]
}
class PriceModuleSection extends Component<IProps> {
  getData = (key: EPackage) => {
    const data = this.props.data.filter((e) => e.Name === key)
    if (data.length <= 0) return null
    const price: IPrice = data[0]
    return {
      title: price.Name as EPackage,
      descriptions: _.sortBy(JSON.parse(price.Description), (elm) => elm.index) as IDescriptionPrice[],
    }
  }

  getTitle = (key: EPackage) => {
    switch (key) {
      case EPackage.MakeupAndHair1:
        return ''
      case EPackage.MakeupAndHair2:
        return 'More Services'
      case EPackage.MakeupAndHairMore:
        return 'Notes'
      default:
        return ''
    }
  }

  renderItem = (descriptionPrices: IDescriptionPrice[], config: IConfig) => {
    const { lg = 4, md = 4, sm = 12, xs = 12 } = config
    return descriptionPrices.map((e, i) => (
      <div key={i} className={`col-lg-${lg} col-md-${md} col-sm-${sm} col-${xs} mb-20`}>
        <div className="packages-blog wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.5s">
          <div className="packages-blog-title">
            <h3>{e.name}</h3>
            {/* <span className="sk-price">
              $22 <span className="sk-price-meta">/monthly</span>
            </span> */}
          </div>
          <div className="packages-blog-info">
            <ul>
              {e.jsonContent.map((elm, idx) => (
                <li key={idx} className='font-family-gruppo'>{elm}</li>
              ))}
            </ul>
          </div>
          {/* <div className="btn-outer">
            <a href="#" className="theme-btn btn-secondary">
              get started
              <span />
            </a>
          </div> */}
        </div>
      </div>
    ))
  }

  render() {
    const data = {
      service1: this.getData(EPackage.MakeupAndHair1),
      service2: this.getData(EPackage.MakeupAndHair2),
      more: this.getData(EPackage.MakeupAndHairMore),
    }
    return (
      // Price Module section
      <section className="section price-module-section pt-md-60 pt-30 pb-md-35 pb-10">
        <div className="container">
          <div className="content-title center-align mb-md-30 wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.5s">
            <span className="title-logo">
              <img
                src={AppConstants.baseImageUrl + 'images/title-logo.png'}
                alt="Love Ring"
                title="Love Ring"
                style={{ height: AppConstants.heightLogoTitle, width: 'auto' }}
              />
            </span>
            <h2>Services</h2>
            <span className="sub-title">Choose Yours</span>
          </div>
          <div className="content-row grid-15">
            {data.service1 && (
              <Fragment>
                <div className="row">{this.renderItem(data.service1.descriptions, {})}</div>
              </Fragment>
            )}
            {data.service2 && (
              <Fragment>
                <h6 className="sub-title" style={{ marginTop: '36px', textAlign: 'center' }}>
                  {this.getTitle(data.service2.title)}
                </h6>
                <div className="row">{this.renderItem(data.service2.descriptions, { lg: 6, md: 6 })}</div>
              </Fragment>
            )}
            {data.more && (
              <Fragment>
                <h6 className="sub-title" style={{ marginTop: '36px', textAlign: 'center' }}>
                  {this.getTitle(data.more.title)}
                </h6>
                <div className="row">
                  <div className="col-12 mb-20">
                    <div className="packages-blog wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.5s">
                      <div className="content-row grid-15">
                        <div className="row">
                          {data.more.descriptions.map((e, i) => (
                            <div className={`col-lg-4 col-md-4 col-sm-12 col-12 mb-20`}>
                              <div className="packages-blog-title">
                                <h3>{e.name}</h3>
                              </div>
                              <div className="packages-blog-info">
                                <ul>
                                  {e.jsonContent.map((elm, idx) => (
                                    <li key={idx} className='font-family-gruppo'>{elm}</li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </section>
    )
  }
}

export default PriceModuleSection
