import _ from 'lodash'
import { Component, FC, Fragment } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import SideNav from '../../components/SideNav'
import { AppConstants } from '../../utility'
import { EGallery, IGallery } from '../../models'
import { PortfolioReduxProps } from '../../redux/reducers/maps/PortfolioRedux'
import { ENavigateKey } from '../../route/NavigateKey'
import { styled } from '@mui/material'
import ImageWithErrorFallback from '../../components/ImageWithErrorFallback'

interface IProps extends PortfolioReduxProps {}

class PortfolioContent extends Component<IProps & { navigate: NavigateFunction }> {
  get galleriesGallery(): IGallery[] {
    const galleries = this.props.PortfolioSlice.Galleries.filter((e) => e.Type !== EGallery.PortfolioSlide)
    return galleries.sort((a, b) => {
      return new Date(b.DateCreated).getTime() - new Date(a.DateCreated).getTime()
    })
  }
  get imagesPortfolioSide(): IGallery[] {
    const temp = this.props.PortfolioSlice.Galleries.filter((e) => e.Type === EGallery.PortfolioSlide)
    return _.get(temp, '[0].ServiceImages', [])
  }

  handleClickNavToDetail = (gallery: IGallery) => {
    this.props.navigate(`${ENavigateKey.GalleryDetail}?id=${gallery.Id}`)
  }

  getClassName = (key: EGallery) => {
    switch (key) {
      case EGallery.GalleryWedding:
        return { className: 'wedding', tag: 'Wedding' }
      case EGallery.GalleryEngagement:
        return { className: 'engagement', tag: 'Engagement' }
      case EGallery.GalleryFamily:
        return { className: 'family', tag: 'Family' }
      case EGallery.GalleryHairAndMakeup:
        return { className: 'hair-and-makeup', tag: 'Hair and Makeup' }
      case EGallery.Maternity:
        return { className: 'maternity', tag: 'Maternity' }
      default:
        return { className: '', tag: '' }
    }
  }

  render() {
    return (
      <Fragment>
        {/* header sidenav  */}

        <SideNav />

        <div className="page-wrapper">
          <div className="body-cover"></div>

          {/* Preloader */}
          <div className="preloader"></div>

          {/* Main Header */}
          <Header />
          {/* End Main Header */}

          <main>
            {/* wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.5s */}

            {/* Gallery section */}
            <section className="section gallery-section pt-md-100 pt-45">
              <div className="container">
                <div
                  className="content-title center-align mb-md-50 mb-20 wow fadeInUp"
                  data-wow-delay="0.3s"
                  data-wow-duration="0.5s"
                >
                  <h2 style={{ textTransform: 'uppercase' }}>Portfolio</h2>
                  <span className="sub-title" style={{ textTransform: 'none' }}>
                    Daily moments turned into timeless portraits
                  </span>
                </div>

                {/* Gallery section */}
                <div className="sk-slider-pp wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.5s">
                  <div className="container max-container-1070">
                    <div className="portfolio-carousel-three owl-carousel owl-theme">
                      {this.imagesPortfolioSide.map((e, i) => {
                        let ImageUri = e.ImageUri
                        let url = 'images/portfolio/portfolio-photo79.png'
                        if (ImageUri) url = AppConstants.baseUrl + ImageUri
                        return (
                          <div key={i} className="item">
                            <a href={url} data-fancybox="gallery">
                              <CustomImage ratio={0.56}>
                                <img src={url} alt="portfolio-picture" title="portfolio-picture" />
                              </CustomImage>
                            </a>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
                {/* End Gallery section*/}
                <div style={{ height: '48px' }} />
                <div className="content-row wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.5s">
                  <div className="portfolioFilter mb-45">
                    <a href="javascript:;" data-filter="*" className="current">
                      SHOW ALL
                    </a>
                    <a href="javascript:;" data-filter=".wedding">
                      WEDDING
                    </a>
                    <a href="javascript:;" data-filter=".engagement">
                      ENGAGEMENT
                    </a>
                    <a href="javascript:;" data-filter=".family">
                      FAMILY
                    </a>
                    <a href="javascript:;" data-filter=".hair-and-makeup">
                      HAIR AND MAKEUP
                    </a>
                    <a href="javascript:;" data-filter=".maternity">
                      MATERNITY
                    </a>
                  </div>
                  <div className="portfolioContainer row">
                    {this.galleriesGallery.map((e, i) => {
                      const obj = this.getClassName(e.Type)
                      const cn = `col-lg-4 col-md-6 col-sm-12 col-12 mb-30 isotope-item ${obj.className}`
                      return (
                        <div key={i} className={cn} onClick={() => this.handleClickNavToDetail(e)}>
                          <div className="gallery-item" style={{ cursor: 'pointer' }}>
                            <CustomImage ratio={1.35}>
                              <ImageWithErrorFallback
                                src={e.ImageUri}
                                baseUrl={AppConstants.baseUrl}
                                fallbackSrc={'images/portfolio/portfolio-photo1.png'}
                              />
                            </CustomImage>
                            <div className="gallery-hover hover-style-one">
                              <span className="gallery-popup">+</span>
                            </div>
                          </div>
                          <div className="gallery-info">
                            <h3>{e.Title}</h3>
                            {/* <span className="sub-title">{obj.tag}</span> */}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  {/* <div className="btn-outer center-align mt-30 wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.5s">
                    <a href="#" className="theme-btn">
                      LOAD MORE
                      <span />
                    </a>
                  </div> */}
                </div>
              </div>
            </section>
            {/* End Gallery section*/}
          </main>

          {/* Main Footer */}
          <Footer />
          {/* End Main Footer */}
        </div>
      </Fragment>
    )
  }
}
const PortfolioScreen: FC<IProps> = (props) => <PortfolioContent {...props} navigate={useNavigate()} />
export default PortfolioScreen

const CustomImage = styled('div')<{ ratio: number }>(({ ratio }) => ({
  width: '100%',
  paddingTop: `${100 * ratio}%`,
  position: 'relative',
  cursor: 'pointer',
  '& img': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}))
