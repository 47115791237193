export enum EGallery {
  // home
  Gallery = 0,
  OurTeam = 1,
  Carousel = 2,
  CarouselMobile = 3,
  PhotographyImage = 4,
  HomePortfolio = 5,
  BackgroundContact = 6,
  // service
  PhotographyAbout = 7,
  PhotographySlide = 8,
  MakeupAndHairAbout = 9,
  // Portfolio
  PortfolioSlide = 10,
  // Gallery
  GalleryWedding = 11,
  GalleryEngagement = 12,
  GalleryFamily = 13,
  GalleryHairAndMakeup = 14,
  Maternity = 15
}
export interface IGallery {
  Id: number
  DateCreated: string
  DateUpdated: string
  Title: string
  ImageUri: string
  Type: number
  Description?: string
  ServiceImages?: IServiceImage[]
}
export interface IInstagram {
  id: string
  media_type: string
  media_url: string
  timestamp: string
  caption: string
}
export enum EServiceImage {
  ForSlideshow, // 450x500
  ForShowAny, // any
}
export interface IServiceImage {
  Id: number
  DateCreated: string
  DateUpdated: string
  Title: string
  ImageUri: string
  Type: EServiceImage
  Description: string
}
export interface IDescriptionData {
  index: number
}
export interface IServiceImageDTO extends Omit<IServiceImage, 'Description'> {
  Description: IDescriptionData
}
export enum ETopic {
  Home = 'Home',
}
export interface IBlog {
  Id: number
  DateCreated: string
  DateUpdated: string
  Title: string
  ImageUri?: string
  Content: string
}
export interface ITopic {
  Id: number
  DateCreated: string
  DateUpdated: string
  Name: string
  Decription: string
  ImageUri: string
  Blogs?: IBlog[]
}
export enum EPackage {
  Photography = 'Photography',
  MakeupAndHair1 = 'MakeupAndHair1',
  MakeupAndHair2 = 'MakeupAndHair2',
  MakeupAndHairMore = 'MakeupAndHairMore',
}
export interface IPrice {
  Id: string
  Amount: number
  Name: EPackage
  Description: string
}
export interface IDescriptionPrice {
  name?: string
  price?: number
  tag?: string
  index?: number
  jsonContent: string[]
}
