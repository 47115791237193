import { createAsyncThunk } from '@reduxjs/toolkit'
import { IGallery } from '../../../models'
import GalleryService, { GALLERIES_PORTFOLIO } from '../../../services/GalleryService'

interface IThunkResult {
  Galleries: IGallery[]
}
export const fetchPortfolioThunk = createAsyncThunk<IThunkResult>('fetchPortfolioThunk', async (_, thunkAPI) => {
  const Galleries = await GalleryService.All(GALLERIES_PORTFOLIO, thunkAPI.signal)
  return { Galleries }
})
