import { IInstagram } from '../models'
import { AppConstants } from '../utility'
import { CreateHttpService } from './Getway'
import ServiceBase from './Helpers/ServiceBase'

class _InstagramService extends ServiceBase {
  constructor() {
    super(CreateHttpService(AppConstants.baseUrl))
  }
  _UrlAll = '/instegram/media/photos'
  All = async (signal?: AbortSignal) => {
    try {
      const res = await this.Get<any>(this._UrlAll, { signal })
      return (res['data'] ?? []) as IInstagram[]
    } catch (error) {
      return []
    }
  }
}

const InstagramService = new _InstagramService()
export default InstagramService
