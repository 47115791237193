import { Component, Fragment } from 'react'
import { AppConstants } from '../../utility'
import { PhotographyReduxProps } from '../../redux/reducers/maps/PhotographyRedux'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import SideNav from '../../components/SideNav'
import PriceModuleSection from './PriceModuleSection'
import { MakeupAndHairReduxProps } from '../../redux/reducers/maps/MakeupAndHairRedux'
import { EGallery, IInstagram, IDescriptionPrice, IServiceImage } from '../../models'
import _ from 'lodash'
import { ImageRatio } from '../../components/ImageRatio'
import { styled } from '@mui/material'

interface IServiceData {
  title: string
  descriptions: IDescriptionPrice[]
}

interface IProps extends MakeupAndHairReduxProps {}

export default class MakeupAndHairScreen extends Component<IProps> {
  get instagrams(): IInstagram[] {
    return this.props.MakeupAndHairSlice.Instagrams.filter((e) => !!e.media_url && e.media_type === 'IMAGE')
  }
  getImageFromGallery = (galleryType: EGallery) => {
    const gallery = this.props.MakeupAndHairSlice.Galleries.find((e) => e.Type === galleryType)
    return _.get(gallery, 'ServiceImages', []) as IServiceImage[]
  }
  render() {
    const path = _.get(this.getImageFromGallery(EGallery.MakeupAndHairAbout), '[0].ImageUri')
    return (
      <Fragment>
        {/* header sidenav  */}

        <SideNav />

        <div className="page-wrapper">
          <div className="body-cover"></div>

          {/* Preloader */}
          <div className="preloader"></div>

          {/* Main Header */}
          <Header />
          {/* End Main Header */}

          <main>
            {/* wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.5s */}

            {/* Gallery section */}
            <section className="section overflow-hidden pt-md-100 pt-45">
              <div className="container">
                <div
                  className="content-row portfolio-fullscreen-slider content-bottom wow fadeInUp"
                  data-wow-delay="0.3s"
                  data-wow-duration="0.5s"
                >
                  <div className="row no-gutters">
                    <div className="col-lg-4 order-lg-first order-last col-md-12 col-sm-12 col-12">
                      <div className="content-title mb-30 wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.5s">
                        <span className="top-title">Makeup And Hair</span>
                        <h2>MADE WITH LOVE</h2>
                        <p className='font-family-gruppo'>
                          Get your glam done by me. Event makeup and hair my specialty. Bringing the best of your natural beauty.
                        </p>
                        {/* <a href="#" className="load-more">
                          read more
                        </a> */}
                      </div>
                    </div>
                    <div className="col-lg-8 order-lg-last order-first col-md-12 col-sm-12 col-12">
                      <div className="portfolio-fullscreen-slider-outer">
                        <div className="portfolio-carousel-two owl-carousel owl-theme">
                          {this.instagrams.map((e, i) => (
                            <div key={i} className="item">
                              <a href={e.media_url} className="gallery-item" data-fancybox="gallery">
                                <CustomImage ratio={0.859}>
                                  <img src={e.media_url} alt="makeup-and-hair-picture" title="makeup-and-hair-picture" />
                                </CustomImage>
                                <div className="gallery-hover">
                                  <span className="gallery-popup">+</span>
                                </div>
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* End Gallery section */}

            {/* Image Section (Left side) */}
            <section className="section pb-md-60 pb-30">
              <div className="container">
                <div className="content-row content-middle">
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="content-img wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.5s">
                        <img src={AppConstants.baseUrl + path} alt="wedding-photo" title="wedding-photo" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div
                        className="content-title tablet-center pl-xxl-100 pl-xl-75 pl-lg-50 pt-lg-0 pt-md-100 pt-60 wow fadeInUp"
                        data-wow-delay="0.3s"
                        data-wow-duration="0.5s"
                      >
                        <span className="title-logo">
                          <img
                            src="images/title-logo.png"
                            alt="Love Ring"
                            title="Love Ring"
                            style={{ height: AppConstants.heightLogoTitle, width: 'auto' }}
                          />
                        </span>
                        <h2>About Lan Le</h2>
                        {/* <span className="sub-title">WHO WE ARE</span> */}
                        <p className='font-family-gruppo'>
                          Lan graduated from Empire Beauty School with distinguished recognition in 2000. Due to her natural
                          curiosity and passion for all that is beauty, she began her professional career learning from in-house
                          artists at salons, spas, and beauty clinics all throughout Philadelphia. With over 20 years of
                          experience, Lan now boasts a vast skill set in both professional hair and makeup. Lan values the needs
                          of her clients and her priority is to always make them the happiest on their special day. She makes sure
                          that her work is done in an efficient and timely manner, reducing any unnecessary stress for her
                          beautiful brides on their memorable day!
                        </p>
                        {/* <a href="#" className="theme-btn">
                          READ MORE
                          <span />
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* End Image Section (Left side) */}

            <PriceModuleSection data={this.props.MakeupAndHairSlice.Prices} />
          </main>

          {/* Main Footer */}
          <Footer />
          {/* End Main Footer */}
        </div>
      </Fragment>
    )
  }
}

const CustomImage = styled('div')<{ ratio: number }>(({ ratio }) => ({
  width: '100%',
  paddingTop: `${100 * ratio}%`,
  position: 'relative',
  cursor: 'pointer',
  '& img': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}))
