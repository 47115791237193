import { combineReducers } from 'redux'
import HomeSlice from './slices/HomeSlice'
import PhotographySlice from './slices/PhotographySlice'
import MakeupAndHairSlice from './slices/MakeupAndHairSlice'
import PortfolioSlice from './slices/PortfolioSlice'
import ContactUsSlice from './slices/ContactUsSlice'
import GalleryDetailSlice from './slices/GalleryDetailSlice'

const Reducers = combineReducers({
  HomeSlice: HomeSlice.reducer,
  PhotographySlice: PhotographySlice.reducer,
  MakeupAndHairSlice: MakeupAndHairSlice.reducer,
  PortfolioSlice: PortfolioSlice.reducer,
  ContactUsSlice: ContactUsSlice.reducer,
  GalleryDetailSlice: GalleryDetailSlice.reducer,
})

export default Reducers
