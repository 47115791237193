import { FC, ReactNode, useEffect, useState } from 'react'
import { Box, Fade, SxProps, Theme, keyframes, styled } from '@mui/material'

interface IProps {
  sx?: SxProps<Theme>
  IsLazy?: boolean
  showProgress?: boolean
  children?: ReactNode
  delay?: number
}

export const LazyLoadView: FC<IProps> = (props) => {
  const [isIn, setIsIn] = useState(props.IsLazy)
  useEffect(() => {
    const { delay = 300, IsLazy } = props
    if (delay > 0) setTimeout(() => setIsIn(IsLazy), delay)
    else setIsIn(IsLazy)
  }, [props, props.IsLazy])

  return (
    <Box sx={{ position: 'relative', ...(props.sx || {}) }}>
      {props.children}
      <Fade in={isIn} unmountOnExit timeout={{ enter: 0, exit: 350 }}>
        <Wrapper>
          {/* {props.IsLazy && props.showProgress !== false && <CircularProgress sx={{ color: '#fff' }} size="48px" />} */}
          {/* {props.showProgress !== false && Array.from({ length: 10 }, (_, i) => i + 1).map((e) => <Wave key={e} />)} */}
          {/* {props.showProgress !== false && <TextAnimation />} */}
          {props.showProgress !== false && <LoadingAnimation>Loading</LoadingAnimation>}
        </Wrapper>
      </Fade>
    </Box>
  )
}

const loadingAnimation = keyframes`
  0% { content: ''; }
  10% { content: '.'; }
  25% { content: '..'; }
  50% { content: '...'; }
  75% { content: '..'; }
  90% { content: '.'; }
  100% { content: ''; }
`

const LoadingAnimation = styled(Box)({
  '&::after': {
    content: '""',
    display: 'inline-block',
    width: '14px',
    animation: `${loadingAnimation} 1.5s steps(4) infinite`,
  },
})

// const waveAnimation = keyframes`
//   0% { transform: scale(0) }
//   50% { transform: scale(1) }
//   100% { transform: scale(0) }
// `

// const Wave = styled(Box)({
//   width: '5px',
//   height: '100px',
//   background: 'linear-gradient(45deg, cyan, #fff)',
//   margin: '10px',
//   animation: `${waveAnimation} 1s linear infinite`,
//   borderRadius: '20px',
//   ':nth-child(2)': { animationDelay: '0.1s' },
//   ':nth-child(3)': { animationDelay: '0.2s' },
//   ':nth-child(4)': { animationDelay: '0.3s' },
//   ':nth-child(5)': { animationDelay: '0.4s' },
//   ':nth-child(6)': { animationDelay: '0.5s' },
//   ':nth-child(7)': { animationDelay: '0.6s' },
//   ':nth-child(8)': { animationDelay: '0.7s' },
//   ':nth-child(9)': { animationDelay: '0.8s' },
//   ':nth-child(10)': { animationDelay: '0.9s' },
// })

const Wrapper = styled('div')({
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  position: 'fixed',
  background: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 9999,
})
